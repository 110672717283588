<template>
  <div class="empower_service_item_container">
    <div class="top">
      <div class="index">{{ index }}</div>
      <div class="name">{{ name }}</div>
    </div>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  name: 'EmpowerServiceItem',
  props: {
    index: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.empower_service_item_container {
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
  }
  .index {
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: #1651dd;
    border-radius: 50%;
    font-size: 24px;
    color: #ffffff;
  }
  .name {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
    line-height: 48px;
    margin-left: 33px;
    margin-top: 3 px;
  }
  .description {
    font-size: 24px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #020046;
    line-height: 48px;
    margin-left: 88px;
  }
}
</style>
