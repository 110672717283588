<template>
  <div class="empower_container w">
    <div class="empower_container_left">
      <div class="left_title">六个月陪跑 制定最详细的方案</div>
      <div class="left_content">
        将组织8-10人实操私房密训，从方案制定到方案审核，最后到最<br />
        终方案落地，给你一个最合适的创业思路。<br />
        线上系统一对一跟进，定制化合作协议<br />
        方案文案参照库
      </div>
      <div class="left_img">
        <img src="@/assets/images/empower/empower_1.png" alt="" />
      </div>
    </div>
    <div class="empower_container_right">
      <div class="right_title">
        赋能计划服务模块
      </div>
      <div class="service_module">
        <div
          class="service_module_item"
          v-for="item in empowerserviceModule"
          :key="item.index"
          :class="{ active: item.index % 2 === 0 }"
        >
          <EmpowerServiceItem
            :index="item.index"
            :name="item.name"
            :description="item.description"
          ></EmpowerServiceItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpowerServiceItem from './empower-service-item.vue'
export default {
  name: '',
  components: {
    EmpowerServiceItem
  },
  data() {
    return {
      empowerserviceModule: [
        {
          index: 1,
          name: '资源型股东',
          description: '大客户变成不花钱的业务员'
        },
        {
          index: 2,
          name: '消费型股东',
          description: '捆绑优质客户成为股东，分享裂变'
        },
        {
          index: 3,
          name: '拓客方案',
          description: '单场50-500有质量新客户流量'
        },
        {
          index: 4,
          name: '精英入股计划',
          description: '捆绑核心员工，稳定内部团队'
        },
        {
          index: 5,
          name: '拓站系统上线',
          description: '线上实现自动引流、锁定、分佣'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.empower_container {
  display: flex;
  justify-content: center;
  margin-top: 141px;
  margin-bottom: 125px;
  .empower_container_left {
    width: 757px;
    height: 939px;
    margin-right: 47px;
    padding: 43px 27px 0px 36px;
    .left_title {
      font-size: 32px;
      font-weight: 500;
      color: #010343;
      line-height: 44px;
      margin-bottom: 21px;
    }
    .left_content {
      font-size: 24px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #010343;
      line-height: 48px;
      margin-bottom: 88px;
    }
    .left_img {
      width: 677px;
      height: 452px;
    }
  }
  .empower_container_right {
    width: 757px;
    height: 939px;
    padding: 43px 0 0 36px;
    .right_title {
      font-size: 32px;
      font-weight: 500;
      color: #010343;
      line-height: 44px;
      margin-bottom: 50px;
    }
    .service_module_item {
      margin-bottom: 46px;
    }
  }
}

.active {
  padding-left: 128px;
}
</style>
