<template>
  <div class="empower_container">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!--赋能计划标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 赋能计划封面图 -->
    <QbCover :src="src"></QbCover>

    <!-- 赋能计划课程表 -->
    <QbSchedule
      :schedule="require('@/assets/images/empower/empower-schedule.png')"
    ></QbSchedule>

    <!-- 赋能计划内容 -->
    <EmpowerContent></EmpowerContent>

    <!-- 报名组件 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import EmpowerContent from './components/empower-content.vue'
export default {
  name: 'Empower',
  components: {
    EmpowerContent
  },
  data() {
    return {
      title: '《赋能计划》',
      complement: '六个月陪跑  标准化文案图片输出',
      src: require('@/assets/images/empower/empower_cover.png'),
      schedule: require('@/assets/images/empower/empower_schedule.png')
    }
  }
}
</script>

<style lang="less" scoped></style>
